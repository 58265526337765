<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="网点详情" />
    <div>
      <!-- <van-tabs @change='getList' v-model="activeName" color='#428dff'> -->
      <div class="top">
        <div>{{websiteName}}</div>
        <div>{{websiteNumber}}</div>
      </div>
      <!-- <van-tab title="日报" name="day"> -->
      <div v-if="activeName ==='day'">
        <div v-for="(v,i) in list" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
          <div>{{v.ddate}}</div>
          <van-row style="text-align:center" type='flex' justify='space-around'>
            <van-col span="12">
              <div class="title">维修工单量</div>
              <div class="num">{{v.orderct}}</div>
              <div class="title">较上周对比</div>
              <div v-if="v.szazgdl<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.szazgdl}}%</div>
              <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.szazgdl}}%</div>
            </van-col>
            <van-col span="12">
              <div class="title">设备数</div>
              <div class="num">{{v.machinect}}</div>
              <div class="title">较上周对比</div>
              <div v-if="v.szsbs<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.szsbs}}%</div>
              <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.szsbs}}%</div>
            </van-col>
          </van-row>
        </div>
      </div>

      <!-- </van-tab> -->
      <!-- <van-tab title="月报" name="month"> -->
      <div v-if="activeName ==='month'">
        <div v-for="(v,i) in list" :key="i" style="padding:16px;background-color:#fff;margin-top:10px">
          <div>{{v.ddate}}</div>
          <van-row style="text-align:center">
            <van-col span="12">
              <div class="title">维修工单量</div>
              <div class="num">{{v.orderct}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.syazgdl<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.syazgdl}}%</div>
              <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.syazgdl}}%</div>
            </van-col>
            <van-col span="12">
              <div class="title">设备数</div>
              <div class="num">{{v.machinect}}</div>
              <div class="title">较上月对比</div>
              <div v-if="v.sysbs<0" class="downStyle" style="color:#d8210a"><img src='../../assets/icon/down.png'>{{-v.sysbs}}%</div>
              <div v-else class="upStyle"><img src='../../assets/icon/shangsheng.png'>{{v.sysbs}}%</div>
            </van-col>
          </van-row>
        </div>
      </div>

      <!-- </van-tab> -->
      <!-- </van-tabs> -->
    </div>
    <van-overlay :show="isshow">
      <div class="wrapper">
        <van-loading color="#0094ff" size="24px" vertical>加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      list: [],
      websiteName: "",
      websiteNumber: "",
      activeName: this.$route.query.type,
      isshow: false,
    };
  },
  created() {
    this.getList();
    // this.getListMonth();
  },
  methods: {
    //获取数据-月报
    async getListMonth() {
      let nowDate = new Date();
      let date = {
        year: nowDate.getFullYear(), // 获取当前年份
        month: nowDate.getMonth() + 1, //获取当前月份
      };
      let start = date.year + "-01";
      let end =
        date.year + "-" + (date.month > 9 ? date.month : "0" + date.month);
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "11",
        type: "家用",
        startYearMonth: start,
        endYearMonth: end,
        website: this.$route.query.website,
      };
      let res = await this.postRequest("/wechat-analysis/fees-month", params);
      this.listMonth = res.data;
    },
    //获取列表数据
    async getList() {
      this.isshow = true;
      this.list = [];
      let params = {
        openid: getUrlKey("openid", window.location.href),
        moduleId: "111111",
        type: "家用",
        startDate: this.$route.query.startTime,
        endDate: this.$route.query.endTime,
        website: this.$route.query.website,
        // timeConditionType: "day",
        sumType: 1,
        sumMode: "repair.comp",
      };
      if (this.activeName === "day") {
        params.timeConditionType = "day";
      } else {
        params.timeConditionType = "month";
      }
      let res = await this.postRequest("/wechat-analysis/summarys", params);
      res.data.forEach((v) => {
        let szazgdl = 0;
        let szsbs = 0;
        let syazgdl = 0;
        let sysbs = 0;
        szazgdl = (((v.orderct - v.worder) / v.worder) * 100).toFixed(2) * 1;
        szsbs =
          (((v.machinect - v.wmachine) / v.wmachine) * 100).toFixed(2) * 1;
        syazgdl = (((v.orderct - v.morder) / v.morder) * 100).toFixed(2) * 1;
        sysbs =
          (((v.machinect - v.mmachine) / v.mmachine) * 100).toFixed(2) * 1;
        v.szazgdl = szazgdl === Infinity ? 0 : szazgdl;
        v.szsbs = szsbs === Infinity ? 0 : szsbs;
        v.syazgdl = syazgdl === Infinity ? 0 : syazgdl;
        v.sysbs = sysbs === Infinity ? 0 : sysbs;
        this.websiteName = v.name;
        this.websiteNumber = v.afterNumber;
      });
      this.list = res.data;
      this.isshow = false;
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.top {
  padding: 16px 0 16px 9px;
  font-weight: 700;
  font-size: 12px;
  background-color: #fff;
}
.title {
  font-size: 12px;
  color: #afafaf;
  margin-bottom: 5px;
  padding-top: 16px;
}
.num {
  font-size: 16px;
  color: #1296db;
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
  }
}
</style>